import React from 'react';
import {Row, Col} from 'react-bootstrap';

// Capabilities by NAICS code page

function NAICS() {
    return (
        <>
            {/* Section I - Heading */}
            <Row className='heading_block'>
                <h2>Government Contracting</h2>
            </Row>

            {/* Section II - List of codes and capabilities */}
            
            <Row className='naics justify-content-center'>
                <Col style={{maxWidth:'800px'}}>
                    <b>SBA Certified Small Disadvantaged Business</b>
                    <p>These are SICORP&apos;s NAICS codes, as required for government contracting:</p>
                    <h3>NAICS 541511</h3>
                    <ul>
                        <li>Custom computer applications software programming services</li>
                        <li>Custom computer program or software development</li>
                        <li>Custom computer programming services</li>
                        <li>Custom computer software analysis and design services</li>
                        <li>Custom computer software programming services</li>
                        <li>Custom computer software support services</li>
                        <li>Web (i.e. Internet) page design services, custom</li>
                    </ul>
                    <h3 className='mt-4'>NAICS 541512</h3>
                    <ul>
                        <li>CAD (computer-aided design) systems integration design services</li>
                        <li>CAE (computer-aided engineering) systems integration design</li>
                        <li>CAM (computer-aided manufacturing) systems integration design</li>
                        <li>Computer hardware consulting services or consultants</li>
                        <li>Computer software consulting services or consultants</li>
                        <li>Computer systems integration analysis and design services</li>
                        <li>Computer systems integration design consulting services</li>
                        <li>Computer integrator services</li>
                        <li>Information management computer systems integration design services</li>
                        <li>Local Area Network (LAN) computer systems integration design services</li>
                        <li>Computer network systems design services</li>
                        <li>Office automation computer systems integration design services</li>
                    </ul>
                    <h3 className='mt-4'>NAICS 541513</h3>
                    <ul>
                        <li>Computer systems facilities management and operation services</li>
                        <li>Data processing facilities management and operation services</li>
                        <li>Computer systems or data processing facilities management and operation services</li>
                        <li>Computer systems or data process facilities support services</li>
                    </ul>
                    <h3 className='mt-4'>NAICS 541519</h3>
                    <ul>
                        <li>Computer disaster recovery services</li>
                        <li>Computer software installation services</li>
                        <li>Ancillary Support (i.e. clerical support; training; construction, alteration and repair; telecommunications/wireless transport, additional labor categories, etc. (Must be integral and necessary for the IT services-based solution.)</li>
                    </ul>
                </Col>
            </Row>
            
            {/* Section III - Past Performance timeline */}
        </>
    );
};

export default NAICS;