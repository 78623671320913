import React, {useEffect} from 'react';
import {Row, Col, Image} from 'react-bootstrap';

// About page

function About() {
  // used for lazy loading background images
  useEffect(() =>{
    // check if the api is supported by the browser
    if ('IntersectionObserver' in window) {
      // set options for the IntersectionObserver
      let options = {
          rootMargin: "100px", // area around the object the detect for loading
      };

      // get all elements with className .benefits
      const images = document.querySelectorAll('.bg_image');

      // create new Intersection observer with the abive options and entries
      let observer = new IntersectionObserver(function (entries, self) {
          entries.forEach(entry => {
              // check if the object is in the viewport
              if (entry.isIntersecting) 
                { 
                  // add the background image using the dataset in the element
                  entry.target.style.backgroundImage = "url('"+entry.target.dataset.bgimage+"')";
                  self.unobserve(entry.target) // stop watching the event
                }
          });
      }, options);

      // add an observer to each element
      images.forEach(image => { observer.observe(image) });

    } else {
      // load all background images automatically if the browser does not support
      const headers = document.querySelectorAll('.bg_image');

      // add background image
      headers.forEach(header => {
          header.style.backgroundImage = "url('"+header.dataset.bgimage+"')";
      });
    }
  })
  
  return(
    <>
      {/* Section I - Heading */}
      <Row className='heading_block'>
          <h2>ABOUT US</h2>
      </Row>

      {/* Section II - Trusted and Experienced */}
          <Row className='justify-content-center about'>
              <Col>
                  <h3>Trusted and Experienced</h3>
                  <p>
                    SICORP is a minority-owned, Small Disadvantaged Business (SDB) boutique technology integrator serving government scientific research laboratories, civilian agencies, and commercial customers.<br/>
                    We are a federal contractor that can work in classified environments.<br/>
                    For more than 25 years, we have supported information technology efforts critical to national security.<br/>
                    We work with some of the most advanced systems in the world to help our customers solve complex issues in Biosecurity, Genomics, Geophysics, and High Performance Computing.
                  </p>
              </Col>
              <Col className='text-center'>
                <Image src='/media/Slide_006-819.jpg' alt='Logo in space' width='500px' height='auto'/>
              </Col>
          </Row>

      {/* Section II - Areas of Expertise */}
      <Row className='heading_block'>
          <h2>AREAS OF EXPERTISE</h2>
      </Row>
      <Row className='bg_image justify-content-center' data-bgimage="/media/big_bug_003_thin.jpg">
          <Row className='justify-content-center'>
              <Col className='grey_text_boxes'>
                  <p>
                  As a full-service information technology supplier with particularly strong experience in high performance computing, networking, and parallel file systems, SICORP provides hardware, systems engineering and administration, software, network infrastructure, lifecycle data management, and managed services.<br/>
                  Our product offerings focus on high-end scientific and enterprise IT clusters, including servers, storage, and networking equipment.
                  </p>
              </Col>

              {/* Lists all expertise */}
              <Col className='grey_text_boxes'>
                <h3 className='text-center'>Specializations</h3>
                <ul style={{textAlign:"left"}}>
                  <li>High Performance Computing (HPC)</li>
                  <li>Bioinformatics</li>
                  <li>Cloud and Virtualization Solutions</li>
                  <li>Infrastructure and Lifecycle Management</li>
                  <li>Call Center / Data Center Operations</li>
                  <li>Applications Development</li>
                  <li>Technology Integration and Modernization</li>
                </ul>
              </Col>
          </Row>
        </Row>
      <Row className='heading_block'/>

      {/* Section III - Services */}
      <Row className='text-center justify-content-center list'>
        <Row className="justify-content-center">

          {/* Personalized Service */}
          <Col>
            <h3>Truly Personalized Service</h3>
            <p>Our core mission is to truly understand our clients&apos; goals and objectives and help them make choices that achieve those goals.<br/>
            As a result, we spend the time to gain an intimate understanding of our customers&apos; needs and challenges, before applying our deep expertise in existing and emerging technologies to make the best possible technology decisions.
            </p>
          </Col>
          
          {/* Always Innovating */}
          <Col>
            <h3>Always Innovating</h3>
            <p>SICORP&apos;s headquarters is located in a 60,000 sq. ft. commercial data center, where we maintain an extensive research and development lab that is dedicated to testing various configurations of equipment and software.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">

          {/* Security and Safety Record */}
          <Col>
            <h3>Security and Safety Record</h3>
            <p>
              All SICORP employees understand the Safety, Security, and Environmental policies and procedures associated with Classified and Unclassified efforts.<br/>
              We are serious about site security and the safety of all of our employees.<br/>
              SICORP has maintained a TRC rate and a DART incidence rate of zero for almost a decade, and to date, has no work-related injuries.
            </p>
          </Col>
          
          {/* Operations and Accounting */}
          <Col>
            <h3>Operations and Accounting</h3>
            <p>
            SICORP utilizes an industry-leading ERP software for managing the entire project lifecycle, including robust contract management, project accounting, manufacturing, and more.<br/>
            SICORP has been in compliance with DCAA regulations since 2006, and operates under an ISO 9001 Quality Management model.
            </p>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default About;