import React from 'react';
import {Row, Col, Button} from 'react-bootstrap';

// 404 Page

function NotFoundPage() {
    return(
        <>
            <Row className="justify-content-center">
                <Col md={4} className="grey_text_boxes">
                    <h3>404 - Not Found</h3>
                    <p>Sorry, this page does not exist.</p>
                    <Col>
                        <Button variant='secondary' href='/' className="me-3">Home</Button>
                        <Button variant='outline-secondary' onClick={()=>{window.history.back()}}>Go back</Button>
                    </Col>
                </Col>
            </Row>
        </>
    );
};

export default NotFoundPage;