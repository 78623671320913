import React, {useEffect, useState} from 'react';
import './tickertape.scss'; // Import CSS for styling

// Tickertape component used on homepage - stated using chat GPT
const TickerTape = ({ images, speed }) => {
  const w = window.innerWidth;
  // const [animationStarted, setAnimationStarted] = useState(false);

  // useEffect(() => {
  //   // Start the animation after a slight delay (e.g., 100ms) to allow layout to settle
  //   const timer = setTimeout(() => {
  //     setAnimationStarted(true);
  //   }, 100);

  //   return () => clearTimeout(timer);
  // }, []);

   return (
     <div className="ticker-tape-container">
          {/* Mapping each image in images parameter to the given path */}
          <div className="ticker-tape" style={{ animationDuration: `${speed}s` }}>
            {images.map((image, index) => (
                <a key={`logo-${index}`} href={image.link} target='_blank' rel="noreferrer" className="ticker-tape-item">
                  <img src={image.src} alt={`Logo ${index}`}/>
                </a>
            ))}
          </div>

          <div className="ticker-tape" style={{ animationDuration: `${speed}s` }} aria-hidden="true">
          {/* Duplicate images to ensure continuous scrolling */}
            {images.map((image, index) => (
                <a key={`duplicate-${index}`} href={image.link} target='_blank' rel="noreferrer" className="ticker-tape-item">
                  <img src={image.src} alt={`Duplicate Logo ${index}`}/>
                </a>
            ))}
          </div>

          {w>1600 ?
          <div className="ticker-tape" style={{ animationDuration: `${speed}s` }} aria-hidden="true">
            {images.map((image, index) => (
              <a key={`duplicate2-${index}`} href={image.link} target='_blank' rel="noreferrer" className="ticker-tape-item">
                <img src={image.src} alt={`Duplicate 2 Logo ${index}`}/>
              </a>
            ))}
          </div> : null}
     </div>
   );
 };

 export default TickerTape;