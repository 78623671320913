import React, {useState, useEffect} from 'react';
import {Row, Col, Tab, Tabs} from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import Storage from './datacenter';
import HPC from './hpc';

// Capabilities page

function Capabilities() {
    // used for lazy loading background images
    useEffect(() =>{
        // check if the api is supported by the browser
        if ('IntersectionObserver' in window) {
            // set options for the IntersectionObserver
            let options = {
                rootMargin: "100px", // area around the object the detect for loading
            };

            // get all elements with className .benefits
            const images = document.querySelectorAll('.bg_image');

            // create new Intersection observer with the abive options and entries
            let observer = new IntersectionObserver(function (entries, self) {
                entries.forEach(entry => {
                    // check if the object is in the viewport
                    if (entry.isIntersecting) 
                    { 
                        // add the background image using the dataset in the element
                        entry.target.style.backgroundImage = "url('"+entry.target.dataset.bgimage+"')";
                        self.unobserve(entry.target) // stop watching the event
                    }
                });
            }, options);

            // add an observer to each element
            images.forEach(image => { observer.observe(image) });

        } else {
            // load all background images automatically if the browser does not support
            const headers = document.querySelectorAll('.bg_image');

            // add background image
            headers.forEach(header => {
                header.style.backgroundImage = "url('"+header.dataset.bgimage+"')";
            });
        }
    })

    // used for highlighting current tab
    const navigate = useNavigate();
    const location = useLocation();
    
    // Extract the active tab from the URL (if it exists)
    const queryParams = new URLSearchParams(location.search);
    const initialKey = queryParams.get('solution') || 'hpc'; // Default to 'hpc' if no tab in URL

    const [key, setKey] = useState(initialKey);

    // Whenever the active tab changes, update the URL
    const handleSelect = (k) => {
        setKey(k);
        navigate(`?solution=${k}`, { replace: true }); // Update URL without reloading
    };

    useEffect(() => {
        // This effect will ensure that when the component is mounted, it will read the active tab from the URL query param
        const queryParams = new URLSearchParams(location.search);
        const urlTab = queryParams.get('solution');
        if (urlTab) {
        setKey(urlTab);
        }
    }, [location]);


    return (
        <>
            <Row className='heading_block'>
                <h2>Solutions</h2>
            </Row>
            <Row className='heading_text justify-content-center text-center p-4'>
                <Col>
                    <p style={{fontStyle:'italic'}}>Customized, Comprehensive, and Service-Driven</p>
                    <p>SICORP&apos;s core mission is to understand our clients&apos; goals and objectives and help them make choices that achieve those goals. As a result, we spend the time to gain an intimate understanding of our customers&apos; needs and challenges, before applying our deep expertise in existing and emerging technologies to make the best possible technology decisions.</p>
                    <p>SICORP is a full-service information technology supplier with particularly strong experience in high performance computing, networking, and parallel file systems. We provide hardware, systems engineering and administration, software, network infrastructure, lifecycle data management, and managed services.</p>
                    <p><b>Select the capabilities you are interested in below:</b></p>
                </Col>
            </Row>

            {/* Links to show different info depending on selection */}
            <Tabs
                activeKey={key}
                onSelect={handleSelect}
                className='justify-content-center text-center capabilities_tabs'
                variant="underline"
            >
                {/* HPC */}
                <Tab eventKey="hpc" title="High Performance Computing">
                    <HPC/>
                </Tab>
                
                {/* Data Storage and Computational Bio */}
                <Tab eventKey="data-center-it" title=" Data Center Solutions and Services">
                    <Storage/>
                </Tab>
            </Tabs>
        
            {/* Section III - Past Performance timeline */}
        </>
    );
};

export default Capabilities;