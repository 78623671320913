// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticker-tape-container {
  overflow: hidden;
  margin: 30px 0;
  white-space: nowrap;
  width: max-content;
  padding: 0 200px;
}

.ticker-tape {
  display: inline-block;
  animation: ticker-scroll infinite linear normal;
  animation-delay: 0s;
  align-items: center;
}

.ticker-tape-item img {
  margin: 0 50px; /* Adjust as needed */
  max-width: 140px;
  max-height: 72px;
}

@media (max-width: 600px) {
  .ticker-tape-item {
    margin: 0 30px;
  }
}
@keyframes ticker-scroll {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}`, "",{"version":3,"sources":["webpack://./src/tickertape.scss"],"names":[],"mappings":"AAGA;EACI,gBAAA;EACA,cAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;AAFJ;;AAME;EACE,qBAAA;EACA,+CAAA;EACA,mBAAA;EAEA,mBAAA;AAJJ;;AAQE;EACE,cAAA,EAAA,qBAAA;EACA,gBAAA;EACA,gBAAA;AALJ;;AAQE;EACE;IACE,cAAA;EALJ;AACF;AASE;EACE;IACE,4BAAA;EAPJ;EASE;IACE,wBAAA;EAPJ;AACF","sourcesContent":["// Tickertape styling for component - started using chat GPT\n\n// overall container\n.ticker-tape-container {\n    overflow: hidden;\n    margin: 30px 0;\n    white-space: nowrap;\n    width: max-content;\n    padding: 0 200px;\n  }\n  \n  // defines animation\n  .ticker-tape {\n    display: inline-block;\n    animation: ticker-scroll infinite linear normal;\n    animation-delay: 0s;\n    // width: max-content;\n    align-items: center;\n  }\n  \n  // margin between each image\n  .ticker-tape-item img {\n    margin: 0 50px; /* Adjust as needed */\n    max-width: 140px;\n    max-height: 72px;\n  }\n\n  @media (max-width: 600px){\n    .ticker-tape-item{\n      margin: 0 30px;\n    }\n  }\n  \n  // direction of scrolling\n  @keyframes ticker-scroll {\n    0% {\n      transform: translateX(-100%);\n    }\n    100% {\n      transform: translateX(0);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
