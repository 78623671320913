import React, {useState} from 'react';
import {Row, Col, Image, Navbar, Container, Nav, NavDropdown} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

// Header for all pages

function Header({logo, alt, links}) {
  const left_links = [links[0], links[1], links[2], links[3]];
  const right_links = [links[4],links[5]];

  const [show, setShow] = useState(false);
  const handleNavClick = () => {
    setShow(false);
  };

  return (
    <>
      {/* Logo */}
      <Row className="justify-content-center m-3" id='top'>
        <Col md='auto' xs='auto'><a href='/'><Image loading='lazy' fluid src={logo} alt={alt} className='header_logo' width='288px' height='42.84px'/></a></Col>
      </Row>
    
      <Navbar expanded={show} onToggle={() => setShow(!show)} collapseOnSelect expand="xxl" className='head'>
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className='text-center' style={{minWidth:'100%', justifyContent:'center'}}>
            {/* Header Links */}
            <Nav>
                <NavLink to="/" className='header_links' onClick={handleNavClick}>Home</NavLink>
              {left_links.map((link) => (
                <NavLink onClick={handleNavClick} key={link.name} to={link.ref} target={link?.target} rel='nofollow' className='header_links'>{link.name}</NavLink>
                
              ))}
              <NavDropdown title='Contact Us' id='header_contactus'>
                <NavDropdown.Item href='https://sicorp.atlassian.net/servicedesk/customer/portal/40/group/91/create/292' target='_blank' rel='nofollow'>Become a Vendor</NavDropdown.Item>
                <NavDropdown.Item href='https://sicorp.atlassian.net/servicedesk/customer/portal/40/group/91/create/293' target='_blank' rel='nofollow'>Become a Customer</NavDropdown.Item>
              </NavDropdown>
              {right_links.map((link) => (
                
                <NavLink onClick={handleNavClick} key={link.name} to={link.ref} target={link?.target} rel='nofollow' className='header_links'>{link.name}</NavLink>
                
              ))}
              <a className='header_links' href="tel:505-878-9902">505-878-9902</a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;