import React from 'react';
import {Row, Col, Image} from 'react-bootstrap';

// HPC Capabilities Page
// Will include bioinformatics

function HPC() {
    
    return(
        <>
            {/* Section I - Header */}
            <Row className='heading_block'>
                <h2>HPC Capabilities</h2>
            </Row>

            {/* Section II - List of Capabilities */}
                <Row className='justify-content-center m-3'>
                    <Col md='auto' className='m-3'>
                        <h3 style={{textAlign:"left"}}>HPC Networking and Storage Services</h3>
                        <ul style={{textAlign:"left"}}>
                            <li>On-site Consulting</li>
                            <li>Complete HPC Systems</li>
                            <li>Software Development</li>
                            <li>Cluster System Design</li>
                            <li>Cluster Management</li>
                            <li>Parallel File Systems</li>
                            <li>Infiniband</li>
                        </ul>
                    </Col>
                    <Col className='justify-content-center m-3' md='auto'>
                        <Image fluid loading='lazy' src="/media/roadrunner.png" alt='Data Storage Image' width='300px' height='auto'/>
                    </Col>
                </Row>

                
                <Row className='justify-content-center m-3'>
                    <Col className='justify-content-center m-3' md='auto'>
                        <Image fluid loading='lazy' src='/media/desktop.jpg' alt='Storage' width='300px' height='auto'/>
                    </Col>
                    <Col md='auto' className='m-3'>
                        <h3 style={{textAlign:"left"}}>Computational Biology</h3>
                        <ul style={{textAlign:"left"}}>
                            <li>NGS and Bioinformatics</li>
                            <li>Software Development</li>
                            <li>Cluster System Design</li>
                            <li>Cluster Management</li>
                            <li>HPC Storage Systems</li>
                            <li>Pipeline and Job Analysis</li>
                        </ul>
                    </Col>
                </Row>
        </>
    );
};

export default HPC;