import React from 'react';
import {Row, Card, Col, Container} from 'react-bootstrap';
import TickerTape from './tickertape';
import { Link } from 'react-router-dom';

// Homepage

function Home() {
    // defines the paths to images used in tickertape component
    const images = [
        {src:'/media/proxmox-logo-stacked-color_small.png', link:'https://www.proxmox.com'},
        {src:'/media/Logo-Red_Hat-B-Standard-RGB.png', link:'https://www.redhat.com/'},
        {src:'/media/Supermicro_GreenC_NewLogo_WhiteBackground.png', link:'https://www.supermicro.com/'},
        {src:'/media/Dell_Logo-2432_small.png', link:'https://www.dell.com/en-us'},
        {src:'/media/arista.jpg',link:'https://www.arista.com/en/'},
        {src:'/media/ibm_logo.png', link:'https://www.ibm.com'},
        {src:'/media/vdura-rgb-amber.png',link:'https://www.vdura.com/'}
    ];

  return(
    <>
        {/* Section I - Header */}
        <Row className="justify-content-center heading_text p-4">
            <Col className='text-center'>
                <p>SICORP is recognized as a leading provider of high performance computing solutions, integrated information technology solutions, and professional consulting services for the Federal Government.</p>
            </Col>
        </Row>

        <Row className="justify-content-center px-2">
            <Col md='auto' className='text-center'>
                <h3>Products We Proudly Represent:</h3>
            </Col>
        </Row>

        {/* Tickertape component that automatically scrolls through logos we support */}
        <TickerTape images={images} speed={20}/>
    
        {/* Section II - Cards/Capabilities */}
        <Row className='heading_block'/>
        <Container style={{minWidth:"100%"}} className='bg_image test'>
            <Row className="my-3 justify-content-center">
                <Row className="justify-content-center home_cards">

                <Col className="d-flex justify-content-center" md='auto'>
                    {/* HPC Capabilities */}
                    <Link to='/capabilities/?solution=hpc' onClick={() => window.scrollTo(0, 0)}>
                        <Card style={{ width: '20rem', backgroundColor:'rgb(234, 232, 232)' }} className="text-center shadow_card" border='dark'>
                            <Col className='card_images_container'>
                                <Card.Img src="/media/roadrunner.png" alt='HPC Image'/>
                            </Col>
                            <Card.Body>
                            <Card.Title>High Performance Computing</Card.Title>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>

                <Col className="d-flex justify-content-center" md='auto'>
                    {/* Data Storage and computational biology Capabilities */}
                    <Link to='/capabilities/?solution=data-center-it' onClick={() => window.scrollTo(0, 0)}>
                        <Card style={{ width: '20rem', backgroundColor:'rgb(234, 232, 232)' }} className="text-center shadow_card" border='dark'>
                            <Col className='card_images_container'>
                                <Card.Img src="/media/bigcloud.jpg" alt='Data Storage Image'/>
                            </Col>
                            <Card.Body>
                            <Card.Title className='medium'> Data Center Solutions</Card.Title>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>

                <Col className="d-flex justify-content-center" md='auto'>
                    {/* NAICS Capabilities */}
                    <Link to='/naics' onClick={() => window.scrollTo(0, 0)}>
                        <Card style={{ width: '20rem', backgroundColor:'rgb(234, 232, 232)' }} className="text-center shadow_card" border='dark'>
                            <Col className='card_images_container vertical_center'>
                                <Card.Img src="/media/sicorp-sba-logo.png" alt='NAICS Code Image'/>
                            </Col>
                            <Card.Body>
                            <Card.Title>Government Contracting</Card.Title>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                </Row>
            </Row>

        </Container>

        
        <Row className='heading_block'/>

        {/* Section III - Lists */}
        <Row className='text-center justify-content-center list'>
            <Row className="justify-content-center">

                {/* Projects */}
                <Col>
                    <h3>Projects</h3>
                    <p>
                        We work with the most advanced systems in the world to help our 
                        clients solve complex issues in Biosecurity, Genomics, Geophysics, and HPC.
                    </p>
                </Col>
                
                {/* Solutions */}
                <Col>
                    <h3>Solutions</h3>
                    <p>
                        We&apos;ve built or worked on a vast array of projects,
                        including biosecurity and public health, for national safeguards and global security.
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-center">
                
                {/* Staff */}
                <Col>
                    <h3>Staff</h3>
                    <p>
                    SICORP employs a team of professional services consultants specialized
                    in the areas of HPC benchmarking, systems administration, application development, and IT logistics.
                    </p>
                </Col>
                
                {/* Clients */}
                <Col>
                    <h3>Clients</h3>
                    <p>
                    28 years of satisfied clients, from research laboratories and municipalities to think tanks, 
                    science foundations, universities, and governmental agencies.
                    </p>
                </Col>
            </Row>
        </Row>

        {/* Not needed?
        <Row className='justify-content-center'>
                <Col className='grey_text_boxes' md={6}>
                    <p>SICORP provides professional services and end-to-end high-performance solutions for enterprises, service providers, federal agencies, and high-availability data centers in need of intelligent and cost-effective ways to manage data.<br/>
                    We prize customer intimacy and prioritize product leadership. Using these values, SICORP has built long-term relationships providing customized technical expertise and comprehensive, service-driven solutions to universities, federal laboratories, military installations, and private research institutes for over 25 years.<br/>
                    SICORP solutions utilize the advanced systems architecture and software technology of our wide network of strategic partners to overcome the limitations of existing computer systems and deliver unparalleled scalability, versatility, and ease of use.</p>
                </Col>
            </Row>
  <Row className='mb-5'/>*/}
    </>
  );
};

export default Home;