import React from 'react';
import {Row, Col, Image} from 'react-bootstrap';

// Storage Capabilities Page - not sure this is right yet

function Storage() {
    return(
        <>
            {/* Section I - Heading */}
            <Row className='heading_block'>
                <h2> Data Center Solutions and Services</h2>
            </Row>

            {/* Section II - List of Storage Capabilities */}
            <Row className='justify-content-center m-3'>
                <Col md='auto' className='m-3'>
                    <h3> Data Center Solutions and Services</h3>
                    <ul>
                        <li>Consulting</li>
                        <li>DCIM Solutions</li>
                        <li>Data Center Materials</li>
                        <li>Portable Data Centers</li>
                        <li>Data Center Management</li>
                        <li>Data Center Operations</li>
                        <li>Fiber Management and Inspection</li>
                        <li>Remote Hands</li>
                    </ul>
                </Col>
                <Col className='justify-content-center m-3' md='auto'>
                    <Image fluid loading='lazy' src="/media/bigcloud.jpg" alt='Data Storage Image' width='300px' height='auto'/>
                </Col>
            </Row>

            {/* Section III - List of Computational Bio Capabilities */}
            {/* <Row className='justify-content-center m-3'>
                <Col className='justify-content-center m-3' md='auto'>
                    <Image fluid loading='lazy' src='/media/desktop.jpg' alt='Storage' width='300px' height='auto'/>
                </Col>
                <Col md='auto' className='m-3'>
                    <h3>Computational Biology</h3>
                    <ul style={{textAlign:"left"}}>
                        <li>Bioinformatics</li>
                        <li>Software Development</li>
                        <li>Cluster System Design</li>
                        <li>Cluster Management</li>
                        <li>HPC Storage Systems</li>
                        <li>Pipeline and Job Analysis</li>
                    </ul>
                </Col>
            </Row> */}
            {/* Section III - Past Performance timeline */}
        </>
    );
};

export default Storage;