// importing all pages and stylesheet

import './App.scss';
import{Routes, Route} from "react-router-dom";
import Home  from './home.js';
import About from './about.js';
import NotFoundPage from './404.js';
import NAICS from './naics.js';
import Capabilities from './capabilities.js';
import Header from './header.js';
import Footer from './footer.js';

// loads all pages to the App

function App() {
  // configuration for header links
  const header_links = [
    {ref:'/about', name:'About'},
    {ref:'/capabilities', name:'Solutions'},
    {ref:'https://sicorp.atlassian.net/servicedesk/customer/portals', name:'Support', target:'_blank'},
    {ref:'https://sicorp.statuspage.io/', name:'Status Page', target:'_blank'},
    {ref:'/naics', name:'Government Contracting'},
    {ref:'https://www.sicorp.jobs', name:'Employment Opportunities', target:'_blank'},
  ];

  return (
    <>

      {/* Defines all paths to each page */}
      <Header logo='/media/sicorp_logo.png' alt='SICORP Logo' links={header_links}/>
        <Routes>
          <Route exact path='' element={<Home />}/>
          <Route path='/about' element={<About />}/>
          <Route path='/naics' element={<NAICS/>}/>
          <Route path='*' element={<NotFoundPage/>}/>
          <Route path='/capabilities' element={<Capabilities/>}/>
          <Route path='/capabilities/:id' element={<Capabilities/>}/>
        </Routes>

      <Footer links={header_links}/>
    </>
  );
}

export default App;
